"use strict";

Vue.component('select-dropdown', {
    template: "\n  <div class=\"ui selection fluid search dropdown\"  v-bind:class=\"{ disabled: disabled }\">\n    <input type=\"hidden\"\n      v-bind:name=\"name\"\n      v-bind:value=\"value\">\n    <i class=\"dropdown icon\"></i>\n    <div class=\"default text\">{{placeholder}}</div>\n    <div class=\"menu\">\n      <div v-for=\"currentvalue in options\"\n          class=\"item\" :data-value=\"currentvalue.value!==undefined?currentvalue.value:currentvalue\" :key=\"currentvalue.value!==undefined?currentvalue.value:currentvalue\">\n          {{currentvalue.label!==undefined?currentvalue.label:currentvalue}}\n       </div>\n    </div>\n  </div>\n  ",
    data: function data() {
        return {};
    },
    props: ['name', 'value', 'placeholder', // Each options can have a label property
        'options', 'disabled', 'fulltextsearch'],
    watch: {
        // Updating Semantic UI Dropdown, when value changes
        value: function value(_value) {
            if (_value == '') {
                jQuery(this.$el).dropdownS('clear');
            } else {
                jQuery(this.$el).dropdownS('set selected', _value);
            }
        }
    },
    methods: {
        // Instead of updating the value directly, this
        // method is used to format and place constraints
        // on the input's value
        updateValue: function updateValue(value) {
            // Emit the number value through the input event
            this.$emit('input', value);
        }
    },
    mounted: function mounted() {
        var that = this; // Using Semantic UI Dropdown
        // @Docs: https://semantic-ui.com/modules/dropdown.html#/settings

        jQuery(this.$el).dropdownS({
            selectOnKeydown: false,
            match: 'text',
            fullTextSearch: that.fulltextsearch !== undefined ? that.fulltextsearch : true,
            forceSelection: false,
            message: {
                noResults: __translate('Keine Ergebnisse gefunden.')
            },
            // Update value on change
            onChange: function onChange(value, text, $choice) {
                that.updateValue(value);
            }
        }); // if(this.value !== '') {
        // jQuery(this.$el).dropdown('set selected',this.value)
        // jQuery(this.$el).dropdown('set active',this.value)
        //  }
        // jQuery('.menu').scrollTo('50px')

        jQuery(this.$el).dropdownS('set selected', [this.value]);
    }
});